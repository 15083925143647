<template>
  <div>
    <div class="iframe-container">
      <div class="tally-iframe">
        <iframe
          data-tally-src="https://tally.so/embed/wz1R5Z?hideTitle=1&transparentBackground=1&dynamicHeight=1"
          loading="lazy"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="슈퍼멤버스 구독 완료"></iframe>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
.iframe-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.tally-iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
}
</style>

<script>
export default {
  name: 'Complete',
  props: {
    showCardForm: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    document.head.appendChild(script);
  },
  data() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
